// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'
// import PropTypes from 'prop-types'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
// import { graphql } from 'gatsby'

import StandardPageWrapper from '../components/standard-page-wrapper'
import '../components/standard-page-wrapper/style.less'

import CoverPage from '../components/cover-page'
import '../components/cover-page/style.less'

import BookSchema from '../components/schema/book-schema'
import ProductSchema from '../components/schema/product-schema'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
const pageSchema = {
  title: 'Cover Page',
  slug: '',
  abstract:
    "Today, information about Auroville is abundant. Many people try to make meaning out of Auroville – about its conception, to what direction should we grow towards, and, what are we doing here? But what was Mother’s original Dream and what was her Vision for Auroville back then? We now have more materials on hand to go back in time and retrace her steps. This book is a new compilation of texts and quotes that Mother had left for us. It is our choice to contemplate on this, to reflect what we have made out of Auroville, and for what are we here today. Don't forget we also have to Dream this New World into Being. Don't forget who we are and where we come from and that we are here to do something together.",
  breadcrumbs: [{ title: 'Cover Page', slug: '' }],
}

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Images
// ----------------------------------------------------------------------------
// export const query = graphql`
//   query {
//     cover: file(relativePath: { eq: "cover.jpg" }) {
//       ...max3000
//     }
//   }
// `

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** Page */
/** Page */
const Page = (props) => (
  <StandardPageWrapper className="home-page" pageSchema={pageSchema} {...props}>
    <BookSchema
      data={{
        name: 'A Dream – Aims and Ideals of Auroville',
        description:
          "Today, information about Auroville is abundant. Many people try to make meaning out of Auroville – about its conception, to what direction should we grow towards, and, what are we doing here? But what was Mother’s original Dream and what was her Vision for Auroville back then? We now have more materials on hand to go back in time and retrace her steps. This book is a new compilation of texts and quotes that Mother had left for us. It is our choice to contemplate on this, to reflect what we have made out of Auroville, and for what are we here today. Don't forget we also have to Dream this New World into Being. Don't forget who we are and where we come from.",
      }}
    />
    <ProductSchema
      data={{
        name: 'A Dream – Aims and Ideals of Auroville',
        description:
          "Today, information about Auroville is abundant. Many people try to make meaning out of Auroville – about its conception, to what direction should we grow towards, and, what are we doing here? But what was Mother’s original Dream and what was her Vision for Auroville back then? We now have more materials on hand to go back in time and retrace her steps. This book is a new compilation of texts and quotes that Mother had left for us. It is our choice to contemplate on this, to reflect what we have made out of Auroville, and for what are we here today. Don't forget we also have to Dream this New World into Being. Don't forget who we are and where we come from.",
      }}
    />
    <CoverPage />
  </StandardPageWrapper>
)

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Export
// ----------------------------------------------------------------------------
export default Page
